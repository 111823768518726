<template>
  <v-container fluid>
    <v-widget title="Bancos">
      <template v-slot:toolbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field filled flat single-line hide-details dense clearable solo v-model="busca" prepend-inner-icon="search" background-color="grey lighten-3" label="Busca" />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:widget-content>
        <v-data-table :options.sync="options" :headers="headers" :items="bancos" :search="busca" mobile-breakpoint="0">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.codigo }}</td>
              <td>{{ props.item.descricao }}</td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ListaBancos',
  data() {
    return {
      headers: [
        { text: 'Código', value: 'codigo', width: '100px' },
        { text: 'Descrição', value: 'descricao' },
      ],
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      bancos: 'bancos',
    }),
  },
};
</script>
